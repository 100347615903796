import type { FetchError } from 'ofetch'
import type { ApiErrors } from '~/types/api.type'

type ErrorArrays<T> = {
  [K in keyof T]: string[]
}

export function useApiErrors<T>(naiveApiErrors: Ref<Record<string, boolean>>, ...errors: Ref<FetchError<any> | null>[]) {
  const apiErrorMessages = computed<ApiErrors<Partial<T>>>(() => {
    let errorArrays: ErrorArrays<T> = {} as ErrorArrays<T>

    for (const error of errors) {
      errorArrays = {
        ...errorArrays,
        ...error.value?.data?.errors,
      }
    }

    const apiErrors: ApiErrors<T> = {}
    for (const key in errorArrays)
      apiErrors[key as keyof T] = errorArrays[key as keyof T][0]

    return apiErrors
  })

  watch(apiErrorMessages, () => {
    naiveApiErrors.value = {}
    for (const errorKey in apiErrorMessages.value)
      naiveApiErrors.value[errorKey] = true
  })

  function clearApiErrors() {
    for (const error of errors) {
      error.value = null
    }
    naiveApiErrors.value = {}
  }

  return { apiErrorMessages, clearApiErrors }
}
